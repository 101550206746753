import { request } from '~/utils/factory'

const state = () => ({
  items: {}
})

const actions = {
  fetchAboutData ({ commit }, data = {}) {
    request('pages?slug=about&_embed').then((res) => {
      if (res) {
        commit('setAboutData', res.data[0])
      }
    })
  }
}

const mutations = {
  setAboutData (state, data) {
    state.items = data
  }
}

const getters = {
  getAboutData (state) {
    return state.items
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
