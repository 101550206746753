import axios from 'axios'

const apiHost = 'https://ajimahareshi.enforstudio.id/wp-json/'
const menuApiHost = 'https://ajimahareshi.enforstudio.id/wp-json/menus/v1/menus/'
const pageUrl = apiHost

export const request = (param) => {
  const targetUrl = pageUrl + 'wp/v2/' + param
  return new Promise((resolve, reject) => {
    axios.get(targetUrl, {
      headers: {
        'Content-Type': 'application/json'
      },
      mode: 'no-cors'
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        } else {
          // console.log('error')
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  })
}

export const requestMenu = (param) => {
  const menuUrl = menuApiHost + param
  return new Promise((resolve, reject) => {
    axios.get(menuUrl, {
      mode: 'no-cors'
    })
      .then((res) => {
        if (res.status === 200) {
          resolve(res)
        } else {
          // console.log('error')
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  })
}

export const requestContact = (url, bodyVal) => {
  return new Promise((resolve) => {
    axios({
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      url: apiHost + url,
      data: bodyVal
    })
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        } else {
          console.log('error')
        }
      })
      .catch((e) => {
        console.log(e)
      })
  })
}
