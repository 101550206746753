import { request } from '~/utils/factory'

const state = () => ({
  items: {},
  portfolioGallery: {}
})

const actions = {
  fetchPortfolioData ({ commit }, data = {}) {
    request('pages?slug=portfolio&_embed').then((res) => {
      if (res) {
        commit('setPortfolioData', res.data[0])
      }
    })
  },
  fetchPortfolioGallery ({ commit }, data = {}) {
    request('portfolio').then((res) => {
      if (res) {
        commit('setPortfolioGallery', res.data)
      }
    })
  }
}

const mutations = {
  setPortfolioData (state, data) {
    state.items = data
  },
  setPortfolioGallery (state, data) {
    state.portfolioGallery = data
  }
}

const getters = {
  getPortfolioData (state) {
    return state.items
  },
  getPortfolioGallery (state) {
    return state.portfolioGallery
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
