import { requestMenu } from '~/utils/factory'

const state = () => ({
  items: {}
})

const actions = {
  fetchNavigationData ({ commit }, data = {}) {
    requestMenu('main-menu').then((res) => {
      if (res) {
        commit('setNavigationData', res.data.items)
      }
    })
  }
}

const mutations = {
  setNavigationData (state, data) {
    state.items = data
  }
}

const getters = {
  getMenu (state) {
    return state.items
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
