import { request } from '~/utils/factory'

const state = () => ({
  items: {}
})

const actions = {
  fetchContactData ({ commit }, data = {}) {
    request('pages?slug=contact&_embed').then((res) => {
      if (res) {
        commit('setContactData', res.data[0])
      }
    })
  }
}

const mutations = {
  setContactData (state, data) {
    state.items = data
  }
}

const getters = {
  getContactData (state) {
    return state.items
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
