import { request } from '~/utils/factory'

const state = () => ({
  items: {},
  blogItems: [],
  categories: {},
  blogCatItems: [],
  totalPages: {},
  blogDetail: []
})

const actions = {
  fetchParentData ({ commit }, data = {}) {
    request('pages?slug=blog&_embed').then((res) => {
      if (res) {
        commit('setParentData', res.data[0])
      }
    })
  },
  fetchBlogData ({ commit }, { page, perPage }, data = {}) {
    request(`posts?status=publish&page=${page}&per_page=${perPage}&_embed`).then((res) => {
      if (res) {
        commit('setBlogData', res.data)
        commit('setTotalPage', {
          wpTotal: parseInt(res.headers['x-wp-total']),
          wpTotalPages: parseInt(res.headers['x-wp-totalpages'])
        })
      }
    })
  },
  fetchBlogDetail ({ commit }, param, data = {}) {
    request(`posts?status=publish&slug=${param}&_embed`).then((res) => {
      if (res) {
        commit('setBlogDetail', res.data[0])
      }
    })
  },
  fetchCategoriesData ({ commit }, data = {}) {
    request('categories').then((res) => {
      if (res) {
        commit('setCategoriesData', res.data)
      }
    })
  },
  fetchBlogCategories ({ commit }, { cat, page, perPage }, data = {}) {
    request(`posts?categories=${cat}&page=${page}&per_page=${perPage}&status=publish&_embed`).then((res) => {
      if (res) {
        commit('setBlogCatData', res.data)
        commit('setTotalPage', {
          wpTotal: parseInt(res.headers['x-wp-total']),
          wpTotalPages: parseInt(res.headers['x-wp-totalpages'])
        })
      }
    })
  }
}

const mutations = {
  setParentData (state, data) {
    state.items = data
  },
  setBlogData (state, data) {
    state.blogItems = data
  },
  setCategoriesData (state, data) {
    state.categories = data
  },
  setBlogCatData (state, data) {
    state.blogCatItems = data
  },
  setTotalPage (state, data) {
    state.totalPages = data
  },
  setBlogDetail (state, data) {
    state.blogDetail = data
  }
}

const getters = {
  getParentData (state) {
    return state.items
  },
  getBlogData (state) {
    return state.blogItems
  },
  getCategoriesData (state) {
    return state.categories
  },
  getBlogCatData (state) {
    return state.blogCatItems
  },
  getTotalPage (state) {
    return state.totalPages
  },
  getBlogDetail (state) {
    return state.blogDetail
  }
}

export default {
  state,
  actions,
  mutations,
  getters
}
